import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { formWallpaper, tbocwwb } from "../assets";
import Title from "../components/title";
import { LabelCard } from "../components/cards";
import { StyledFlexSB } from "../styles";
import { Store } from "pullstate";
import { IFormState } from "../views/forms/types";
import { useEffect, useState } from "react";
import { useDataContext } from "../context/DataContext";
import http from "../utils/http";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import CustomUpload from "../components/FileButton";
import { string } from "yup";
import { toast } from "react-toastify";

type Props = {
  name: string;
  value: any;
};

type AutoCompleteProps = {
  e: any;
  newValue: any;
};

export const enclosures = new Store<{
  aadharFile: any;
  applicantPhoto: any;
  sign: any;
}>({
  aadharFile: null,
  applicantPhoto: null,
  sign: null,
});

export const formState = new Store<IFormState>({
  course: {
    id: "",
    name: "",
    courseId: "",
  },
  workerName: "",
  workerMobile: "",
  workerAadharNumber: "",
  workerRationCardNumber: "",
  bocRegNo: "",
  reqcode: "",
  name: "",
  gender: "",
  fatherName: "",
  motherName: "",
  dateOfBirth: "",
  renewedUpto: "",
  caste: "",
  mobile: "",
  aadharNumber: "",
  aadharFile: "",
  education: {
    name: "",
    id: "",
    courses: [],
  },
  applicantPhoto: "",
  houseNumber: "",
  village: "",
  sign: "",
  district: {
    id: "",
    name: "",
    code: "",
  },

  state: "",
  pincode: "",
  batchNumber: "",
  year: "",
});

export const formFieldData = new Store<{
  districts: any[];
  courses: any[];
  education: any[];
}>({
  districts: [],
  courses: [],
  education: [],
});

function Form() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { bocwMember } = useDataContext();
  const state = formState.useState((s) => s);
  const stateFile = enclosures.useState((s) => s);
  const [state1, setState1] = useState<any>({
    label: null,
    value: null,
  });
  const [state2, setState2] = useState<any>({
    value: null,
    name: null,
    val2: null,
  });

  const formData = formFieldData.useState((s) => s);

  useEffect(() => {
    if (!bocwMember) {
      window.location.href = "/";
    }
    formState.update((s) => {
      s.workerName = bocwMember?.worker_name;
      s.name = bocwMember?.traine_name;
    });
    getData();
  }, []);

  const handleChange = (name: string, value: any) => {
    formState.update((s) => {
      s[name] = value;
    });
  };

  const motherNameValidation = (value: any) => {
    var regex = /^[a-zA-Z ]*$/;
    return value.match(regex);
  };

  const isMobileError = (value: any) => {
    var phoneno = /^[0-9]{10}$/;
    return !value.match(phoneno);
  };

  const isAadharValid = (value: any) => {
    var phoneno = /^[0-9]{12}$/;
    return value.match(phoneno);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const [districtRes, education] = await Promise.all([
        http.get(`/district`),
        http.get(`/education`),
      ]);

      formFieldData.update((s) => {
        s.districts = districtRes.data;
        s.education = education.data.data;
      });

      formState.update((s) => {
        s.batchNumber = `B${
          districtRes?.data?.data?.find(
            (item: any) => item.id == bocwMember?.distcode
          )?.id
        }_${format(new Date(), "MMM")}${format(new Date(), "yy")}`;
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handlePreview = (e: any) => {
    e.preventDefault();

    const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (state?.education?.name == "") {
      toast.error("Education is required");
      return;
    }
    if (state?.course?.name == "") {
      toast.error("Course is required");
      return;
    }
    if (!mobileRegex.test(state.mobile) || state?.mobile.includes("-")) {
      toast.error("Enter Valid Mobile Number");
      return;
    }

    if (state?.aadharNumber.length < 12 || state?.aadharNumber.includes("-")) {
      toast.error("Enter valid Aadhar number");
      return;
    }
    if (!state?.motherName) {
      toast.error("Mother Name is required");
      return;
    }
    if (!state.aadharFile) {
      toast.error("Aadhar Card upload is required");
      return;
    }
    if (!state.applicantPhoto) {
      toast.error("Passport photo upload is required");
      return;
    }
    if (!state.sign) {
      toast.error("Signature upload is required");
      return;
    }

    navigate("/preview");
  };

  function addImage(v: any, e: any) {
    formState.update((s) => {
      s[e.target.name] = v.key;
    });
    enclosures.update((s) => {
      s[e.target.name] = v;
    });
  }

  const handleEducation = (value: any) => {
    setState2({
      label: null,
      value: null,
      name: null,
    });
    formState.update((s) => {
      s["education"] = { name: value?.label, id: value?.value, courses: [] };
    });
  };

  const handleCourse = (value: any) => {

    formState.update((s) => {
      s["course"] = {
        name: value?.value,
        id: value?.val2,
        courseId: value?.val2,
      };
    });
  };


  

  

  if (loading) return <CircularProgress />;

  return (
    <Box sx={{ bgcolor: "#FFF5F5" }}>
      <Box pt="160px" display="flex" justifyContent="center">
        <img
          src={tbocwwb}
          alt=""
          style={{
            width: "350px",
            height: "60px",
            objectFit: "cover",
          }}
        />
      </Box>
      <Typography variant="h5" textAlign="center" mt="30px">
        Telangana Building and Other Construction Workers Welfare Board
      </Typography>
      <Typography variant="h6" mt="60px" textAlign="center">
        TBOCWWB Application form for Online Skill Upgradation
      </Typography>
      <Typography variant="h6" mt="10px" textAlign="center">
        Training Programme for Registered BOC Workers and their Dependents
      </Typography>
      <Box display="grid" gridTemplateColumns="4fr 3fr">
        <Box pl="20%" pt="40px">
          <Title value="Course Details" />
          <Box mt="30px" width="95%">
            <LabelCard title="Education Qualification *" />
            <Autocomplete
              id="tags-standard"
              size="medium"
              onChange={(_, value) => {
                setState1(value);
                handleEducation(value);
              }}
              value={state1?.label || state.education.name}
              fullWidth
              options={
                formData?.education?.map((item: any) => ({
                  label: item?.name,
                  value: item?.id,
                })) || []
              }
              renderInput={(params) => (
                <TextField {...params} label="Select Education" />
              )}
            />
            <LabelCard title="Select Course *" mt={3} />
            <Autocomplete
              id="tags-standard"
              size="medium"
              onChange={(_, value) => {
                setState2(value);
                handleCourse(value);
              }}
              value={state.course.name || state2?.value}
              fullWidth
              options={
                formData?.education
                  ?.find((item) => item?.id === state1.value)
                  ?.courses?.map((item: any) => ({
                    label: `Course ${item?.id} - ${item?.name}`,
                    value: `Course ${item?.id} - ${item?.name}`,
                    val2: item?.id,
                    name: item?.name,
                  })) || []
              }
              renderInput={(params) => (
                <TextField {...params} label="Select Course" />
              )}
            />
            <LabelCard title="Select Batch Number" mt={3} />
            <TextField
              size="medium"
              variant="outlined"
              autoComplete="off"
              fullWidth
              disabled
              value={state?.batchNumber}
              placeholder="Course Batch Number"
            />

            <Title value="Member Details" mt={40} />
            <LabelCard title="Member Details" mt={3} />

            <TextField
              size="medium"
              variant="outlined"
              autoComplete="off"
              fullWidth
              required
              disabled
              value={state?.workerName}
              //   label="Enter Registration Number"
            />
            <LabelCard title=" BOCWWB Registration number" mt={3} />

            <TextField
              size="medium"
              variant="outlined"
              autoComplete="off"
              fullWidth
              required
              disabled
              value={bocwMember?.regno}
              //   label="Enter Registration Number"
            />

            <Title value="Dependent Details" mt={40} />
            <LabelCard title="Dependent Name *" mt={3} />

            <TextField
              size="medium"
              variant="outlined"
              autoComplete="off"
              fullWidth
              disabled
              value={state.name}
              //   label="Enter Registration Number"
            />
            <LabelCard title="Mother name *" mt={2} />

            <TextField
              size="medium"
              fullWidth
              variant="outlined"
              required
              error={!motherNameValidation(state.motherName)}
              helperText={
                motherNameValidation(state.motherName)
                  ? ""
                  : "No Special Charaters Allowed"
              }
              value={state.motherName}
              onChange={(e) => handleChange("motherName", e.target.value)}
              placeholder="Mother Name"
              autoComplete="off"
              //   label="Enter Registration Number"
            />
            <LabelCard title="Mobile number of dependent *" />
            <TextField
              fullWidth
              required
              error={isMobileError(state.mobile)}
              helperText={
                isMobileError(state.mobile) ? "Invalid Mobile Number" : ""
              }
              value={state.mobile}
              onChange={(e) => handleChange("mobile", e.target.value)}
              autoComplete="off"
              //   label="Enter Registration Number"
            />
            <LabelCard title="Aadhar number of dependent *" />
            <TextField
              fullWidth
              size="medium"
              variant="outlined"
              autoComplete="off"
              helperText={
                isAadharValid(state.aadharNumber) ? "" : "Invalid Aadhar Number"
              }
              error={!isAadharValid(state.aadharNumber)}
              value={state.aadharNumber}
              onChange={(e) => handleChange("aadharNumber", e.target.value)}
              placeholder="Enter Aadhar number"
              //   label="Enter Registration Number"
            />
          </Box>
        </Box>
        <Box>
          <Box pt={35}>
            <img
              src={formWallpaper}
              alt=""
              style={{ width: "95%", height: "350px", objectFit: "contain" }}
            />
          </Box>
        </Box>
      </Box>
      <Box ml="11.5%">
        <Title value=" Enclosures" mt={40} />
        <Box display="flex" justifyContent="space-between" width="42.5vw">
          <LabelCard title=" Applicant Aadhar File *" mt={3} />
          <CustomUpload
            name="aadharFile"
            type="AADHAR"
            onChange={addImage}
            data={stateFile?.aadharFile?.mediaObject?.originalFileName}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" width="42.5vw">
          <LabelCard title="Passport size photo *" mt={3} />
          <CustomUpload
            name="applicantPhoto"
            onChange={addImage}
            data={stateFile?.applicantPhoto?.mediaObject?.originalFileName}
            type="PHOTO"
          />
        </Box>
        <Box display="flex" justifyContent="space-between" width="42.5vw">
          <LabelCard title="Signature *" mt={3} />
          <CustomUpload
            name="sign"
            onChange={addImage}
            data={stateFile?.sign?.mediaObject?.originalFileName}
            type="SIGNATURE"
          />
        </Box>
        {/* {alertMessage && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {alertMessage}
          </Alert>
        )} */}

        {/* <StyledFlexSB>
          <LabelCard title="Signature *" mt={3} />

          <Button variant="outlined">Upload</Button>
        </StyledFlexSB> */}
        <Button
          sx={{ mt: "20px", mb: "40px", ml: "8%" }}
          onClick={handlePreview}
          variant="contained"
        >
            Preview
        </Button>
      </Box>
    </Box>
  );
}

export default Form;
