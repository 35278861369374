// import loadable from "@loadable/component";
// import Layout from "layout/mainLayout";
// import Login from "pages/login";
// ALOSDPT/2018/02972
// K SUDHARSHAN
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Login from "../pages/login";
import Form from "../pages/form";
import Layout from "../layout/Appbar";
import DataProvider from "./DataContext";
import Preview from "../pages/preview";
import Success from "../pages/Success";
// export const Dashboard = loadable(() => import("pages/dashboard"));
// export const login = loadable(() => import("pages/login"));
const RoutesContainer = () => {
  return (
    <DataProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Navigate to="login" />} />
            <Route path="login" element={<Login />} />
            <Route path="form" element={<Form />} />
            <Route path="preview" element={<Preview />} />
            <Route path="success/:id" element={<Success />} />
          </Route>
        </Routes>
      </Router>
    </DataProvider>
  );
};
export default RoutesContainer;
