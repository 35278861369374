// import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataProvider from "./context/DataContext";
import RoutesContainer from "./routes";
import "./styles/App.css";
import { CssBaseline } from "@mui/material";

// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       refetchOnWindowFocus: false,
//     },
//   },
// });

function App() {
  return (
    <>
      <CssBaseline />
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <DataProvider>
        <RoutesContainer />
      </DataProvider>
    </>
  );
}

export default App;
