import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { wallpaper } from "../assets";
import { useDataContext } from "../context/DataContext";
import http from "../utils/http";

function Login() {
  const [state, setState] = useState({
    regNo: "",
    traineeName: "",
    traineeIs: "D",
  });
  const [loading, setLoading] = useState<boolean>(false);
  // const [error, setError] = useState(null);

  const navigate = useNavigate();
  const { setBocwMember } = useDataContext();

  const handleChange = (name: string, value: string) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await http.post(`/bocw-user`, {
        ...state,
      });

      if (res.data?.statusCode !== "000") {
        toast.error(res.data.statusMsg);
        setLoading(false);
        return;
      }

      setBocwMember(res?.data);
      setLoading(false);
      navigate("/form");
    } catch (err: any) {
      setLoading(false);
      toast.error(err.response.data.message);
    }
  };

  return (
    <Box sx={{ height: "100vh", bgcolor: "#FFF5F5", position: "relative" }}>
      <Box sx={{ position: "absolute", bottom: "0px", right: "0px" }}>
        <img
          src={wallpaper}
          alt=""
          style={{ width: "500px", height: "300px", objectFit: "contain" }}
        />
      </Box>

      <Box pt="130px" display="flex" justifyContent="center">
        {/* <img
          src={appc_tbocwwb}
          alt=""
          style={{
            width: "420px",
            height: "60px",
            objectFit: "cover",
          }}
        /> */}
      </Box>
      <Typography variant="h5" textAlign="center" mt="20px">
        Telangana Building and Other Construction Workers Welfare Board
      </Typography>
      <Typography variant="h6" mt="30px" textAlign="center">
        TBOCWWB Application form for Online Skill Upgradation
      </Typography>
      <Typography variant="h6" textAlign="center">
        Training Programme for Registered BOC Workers and their Dependents
      </Typography>
      <Typography variant="h6" color="#002060" textAlign="center" mt="30px">
        TBOCWWB Training Registration
      </Typography>
      <Box maxWidth="590px" m="0px auto" pt="33px">
        <form onSubmit={handleSubmit}>
          <TextField
            size="medium"
            variant="outlined"
            autoComplete="off"
            fullWidth
            required
            label="Enter Registration Number"
            value={state.regNo}
            onChange={(e) => handleChange("regNo", e.target.value)}
          />
          <Box display="grid" mt="28px" gap={1} gridTemplateColumns="1fr 2fr">
            <Box>
              <FormControl fullWidth>
                <InputLabel htmlFor="grouped-native-select">
                  Select *
                </InputLabel>

                <Select
                  labelId="grouped-native-select"
                  id="demo-simple-select"
                  name="type"
                  value={state.traineeIs}
                  label="Select *"
                  onChange={(e) => handleChange("traineeIs", e.target.value)}>
                  <MenuItem value={"W"}>Worker</MenuItem>
                  <MenuItem value={"D"}>Dependent</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box>
              <TextField
                size="medium"
                variant="outlined"
                autoComplete="off"
                fullWidth
                name="traine_name"
                required
                label="Enter name"
                value={state.traineeName}
                onChange={(e) => handleChange("traineeName", e.target.value)}
              />
            </Box>
          </Box>
          {/* {error && (
            <Box display="flex" alignItems="center">
              <ErrorOutlineIcon sx={{ color: "red" }} />
              <Typography mt={1} color="red" p="15px 20px 15px 20px">
                {error}
              </Typography>
            </Box>
          )} */}

          <Box display="flex" justifyContent="center" mt="30px">
            <Button
              variant="contained"
              type="submit"
              disabled={!(state.regNo !== "" && state.traineeName !== "")}
              endIcon={
                loading ? (
                  <CircularProgress sx={{ color: "white" }} size={20} />
                ) : null
              }>
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default Login;
