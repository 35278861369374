import { Typography } from "@mui/material";

type Props = {
  title: string;
  mt?: number;
};

export const LabelCard = ({ title, mt = 2 }: Props) => {
  return (
    <Typography variant="subtitle2" mt={mt} mb="8px">
      {title}
    </Typography>
  );
};
