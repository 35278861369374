import { AppBar, Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { appc, telangana } from "../assets";

const Layout = () => {
  return (
    <Box>
      <AppBar color="inherit" position="fixed">
        <Box height="100px" display="flex" alignItems="center">
          <Box ml="80px" width="480px" display="flex" gap="40px">
            <img src={telangana} alt="" width="60px" height="60px" />
            {/* <img src={appc} alt="" width="60px" height="60px" /> */}
            {/* <img src={kcr} alt="" width="60px" height="60px" />
            <img src={mallareddy} alt="" width="60px" height="60px" /> */}
          </Box>
        </Box>
      </AppBar>
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
