// import { Button } from "@mui/material";
// import React, { useState } from "react";
// import http from "../utils/http";

// function FileButton() {
//   const [fileName, setFileName] = useState<any>(null);
//   const [loading, setLoading] = useState<boolean>(false);
//   const handleChange = (e: any) => {
//     if (e.target.files[0]) {
//       const formData = new FormData();
//       formData.append("file", e.target.files[0]);
//       setLoading(true);
//       http
//         .post("/upload", formData)
//         .then((res) => {
//           setFileName(e.target.files[0].name);
//           // onChange(res.data, e)
//           setLoading(false);
//         })
//         .catch((err) => {
//           setLoading(false);
//           // toast.error('Server Error While Uploading File')
//         });
//     }
//   };

//   return (
//     <input onChange={handleChange} type="file" accept="image/*">
//       upload
//     </input>
//   );
// }

// export default FileButton;

import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import http from "../utils/http";
import axios from "axios";
import { DataArrayTwoTone } from "@mui/icons-material";

type Props = {
  name: string;
  onChange: any;
  data: any;
  type: any;
};

const CustomUpload = ({ name, onChange, data, type }: Props) => {
  const [fileName, setFileName] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = async (e: any) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);
    setFileName(e.target.files[0].name);

    console.log(e.target.name, "handle files");

    setLoading(true);

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/media-object`,
        data: formData,
      });
      console.log(response, "after  .thennn");
      handleresponse(response, e);
      // onChange(response?.data, e);
      console.log(response, "after onch  .thennn");
      // setPic(response?.data);
      // onChange(response?.data, e);
    } catch (error) {
      console.log("inside catch");
    }
  };

  const handleresponse = (res: any, e: any) => {
    console.log(res, "res in h", e.target.name);
    onChange(res?.data, e);
  };

  // const handleChange = (e: any) => {
  //   console.log("top change");

  //   if (e.target.files[0]) {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0]);
  //     formData.append("type", type);
  //     setLoading(true);
  //     console.log("inside handle chage");

  //     http
  //       .post("/media-object", formData)
  //       .then((res) => {
  //         setFileName(e.target.files[0].name);
  //         console.log("inside then");

  //         onChange(res.data, e);
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         console.log("inside catch");

  //         // toast.error("Server Error While Uploading File");
  //       });
  //   }
  // };

  useEffect(() => {
    setFileName(data);
  }, [data]);

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ position: "relative" }}>
        <label htmlFor={name}>
          <Box
            mt={1}
            border="1px solid #182F53"
            borderRadius="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="55px"
            width="140px"
          >
            <Typography fontSize="16px" color="#182F53" variant="h2">
              Upload
            </Typography>
          </Box>
        </label>
        <input
          onChange={handleChange}
          type="file"
          style={{ display: "none" }}
          id={name}
          name={name}
          accept="image/png, image/jpeg, image/jpg"
        />
        {(fileName || data) && (
          <Typography
            position="absolute"
            top="20px"
            left="150px"
            fontSize="16px"
            noWrap
            color="#182F53"
          >
            {fileName || data}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CustomUpload;
