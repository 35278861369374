import { KeyboardBackspace } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { Button } from "../components";
import { useDataContext } from "../context/DataContext";
// import "../styles/preview-page.css";
import http from "../utils/http";
import { enclosures, formFieldData, formState } from "./form";
// import { enclosures } from "./form/Enclosures";
import { string } from "yup";
import { toast } from "react-toastify";

export default function Preview() {
  // const history = useHistory();
  const navigate = useNavigate();
  const [posting, setPosting] = useState<boolean>(false);
  const state = formState.useState((s) => s);
  const data = formFieldData.useState((s) => s);
  const files = enclosures.useState((s) => s);
  const [alertMessage, setAlertMessage] = useState("");

  let d = data?.districts;

  useEffect(() => {
    if (!bocwMember) {
      window.location.href = "/";
    }
  }, []);

  const { bocwMember } = useDataContext();

  const postData = async () => {
    const props: AxiosRequestConfig = {
      url: "/registration-form",
      method: "POST",
    };

    try {
      setPosting(true);
      const res: any = await http({
        ...props,
        data: {
          ...state,
          higherEducationId: state.education?.id,
          courseId: state.course.id,
          reqCode: bocwMember?.reqcode,
          workerName: bocwMember?.worker_name,
          bocRegNo: bocwMember?.regno,
          districtId: parseInt(bocwMember?.distcode),
          signatureKey: state.sign,
          imageKey: state.applicantPhoto,
        },
      });

      setPosting(false);

      toast.success("Your Application Submitted successfully");

      res && navigate(`/success/${res.data?.formId}`);
    } catch (err: any) {
      // {
      //   err.response.data?.message?.map((i: any) =>
      //     toast.error(err.response.data?.message)
      //   );
      // }

      toast.error(err.response.data?.message);
      setPosting(false);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    postData();
  };

  return (
    <Box
      bgcolor="#FFF5F5"
      pt={12}
      pl={35}
      pr={35}
      pb={2}
      sx={{
        "@media(max-width:1300px)": { pl: 10, pr: 10 },
      }}
    >
      <Box sx={{ mt: 0 }}>
        <Link to="/form">
          <Box display="flex" alignItems="center" gap={1}>
            <KeyboardBackspace />
            <p>Go Back</p>
          </Box>
        </Link>
      </Box>

      <Box
        maxWidth="1100px"
        display="flex"
        gap={2}
        alignItems="center"
        m="auto"
      >
        <Box>
          <Typography
            color="#000000"
            fontSize="22px"
            fontWeight="600"
            textAlign="center"
            pt={4}
          >
            Telangana Building and Other Construction Workers Welfare Board
          </Typography>
          <Typography color="#000000" variant="h6" mt="20px" textAlign="center">
            APPC Application form for Online Skill Upgradation
          </Typography>
          <Typography color="#000000" variant="h6" mt="4px" textAlign="center">
            Training Programme for Registered BOC Workers and their Dependents
          </Typography>
        </Box>
        <img
          src={files.applicantPhoto?.url}
          alt="photo"
          style={{
            marginTop: "8px",
            objectFit: "contain",
            border: "1px solid rgba(0, 0, 0, 0.5)",
            height: "41mm",
            width: "41mm",
          }}
        />
      </Box>
      <Box display="grid" gridTemplateColumns="4fr 4fr" minWidth="1100px">
        <Box pt={2}>
          <Title value="Course Details" />
          <LabelCard title="Education Qualification *" />
          <Typography variant="h6" fontSize="18px" mb={1}>
            {state?.education?.name}
          </Typography>
          <LabelCard title="Course Selected *" />
          <Typography variant="h6" fontSize="18px" mb={1}>
            {state?.course?.name}
          </Typography>
          <LabelCard title="Batch Number *" />
          <Typography variant="h6" fontSize="18px" mb={1}>
            {state?.batchNumber}
          </Typography>

          <Title value="Member Details" />
          <LabelCard title="Member Details *" />
          <Typography variant="h6" fontSize="18px" mb={1}>
            {state?.workerName}
          </Typography>
          <LabelCard title="BOCWWB Registration number *" />
          <Typography variant="h6" fontSize="18px" mb={1}>
            {bocwMember?.regno}
          </Typography>

          <Title value="Dependent Details" />
          <LabelCard title="Dependent Name *" />
          <Typography variant="h6" fontSize="18px" mb={1}>
            {state?.name}
          </Typography>
          <LabelCard title="Mother Name *" />
          <Typography variant="h6" fontSize="18px" mb={1}>
            {state?.motherName}
          </Typography>
          <LabelCard title="Mobile number of dependent *" />
          <Typography variant="h6" fontSize="18px" mb={1}>
            {state?.mobile}
          </Typography>
          <LabelCard title="Aadhar number of dependent *" />
          <Typography variant="h6" fontSize="18px" mb={1}>
            {state?.aadharNumber}
          </Typography>
        </Box>
        <Box m="auto" width="100%" pt={4}>
          <img
            src={files.aadharFile?.url}
            alt=""
            style={{
              maxWidth: "500px",
              margin: "auto",
              maxHeight: "600px",
              objectFit: "fill",
            }}
          />
        </Box>
      </Box>
      <Box sx={{ m: "auto", maxWidth: "1160px" }}>
        <Box
          sx={{
            pt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Box
              width="200px"
              height="100px"
              border="1px solid rgba(0, 0, 0, 0.1)"
            >
              <img
                src={files.sign?.url}
                style={{
                  height: "70px",
                  width: "100%",
                  objectFit: "contain",
                }}
                alt="Signature of candidate"
              />
            </Box>
            <Typography pt={0.5} textAlign="center">
              Signature of the Candidate
            </Typography>
          </Box>

          <Box
            width="200px"
            height="100px"
            border="1px solid rgba(0, 0, 0, 0.1)"
          >
            <div
              style={{
                height: "100px",
                width: "200px",
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            ></div>
            <Typography textAlign="center">Signature of ALO</Typography>
          </Box>
        </Box>
      </Box>

      {/* <Box sx={{ display: "flex", padding: 0, gap: 2 }}> */}
      {/* <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5" textAlign="center" mt="30px">
            Telangana Building And Other Construction Workers Welfare Board
          </Typography>
          <Typography variant="h6" mt="60px" textAlign="center">
            APPC Application form for Online Skill Upgradation Training
            Programme for Registered BOC Workers and their Dependents
          </Typography>
        </Box> */}
      {/* <Box
          sx={{
            border: "1px solid black",
            height: "51mm",
            width: "51mm",
            flexShrink: 0,
          }}
        >
          <img
            height="100%"
            width="100%"
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            src={files.applicantPhoto?.url}
            alt="passport size photo"
          />
        </Box> */}
      {/* </Box> */}

      {/* <Box display="flex" gap={1} mt={2}>
        <Box width="50%">
          <Box>
            <CustomPreviewP
              title="Name of the Worker"
              value={state?.workerName}
            />
            <CustomPreviewP title="BOC CARD Number" value={bocwMember?.regno} />
            <CustomPreviewP
              title="Name of Worker/Dependent"
              value={state?.name}
            />
            <CustomPreviewP
              title="Dependent Mother Name"
              value={state?.motherName}
            />

            <CustomPreviewP
              title="Mobile Number of Dependent"
              value={state?.mobile}
            />
            <CustomPreviewP title="District" value={bocwMember?.distname} />
            <CustomPreviewP
              title="Trainee Aadhar Number"
              value={state?.aadharNumber}
            />
          </Box>
        </Box>
        workerName
        <Box width="40%">
          <img
            src={files.aadharFile?.url}
            alt=""
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Box> */}
      {/* <Box sx={{ mt: 4, p: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            width="200px"
            height="100px"
            border="1px solid rgba(0, 0, 0, 0.1)"
          >
            <img
              src={files.sign?.url}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
              alt="Signature of candidate"
            />
            <Typography textAlign="center">
              Signature of the Candidate
            </Typography>
          </Box>
          <Box
            width="200px"
            height="100px"
            border="1px solid rgba(0, 0, 0, 0.1)"
          >
            <div
              style={{
                height: "100px",
                width: "200px",
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            ></div>
            <Typography textAlign="center">Signature of ALO</Typography>
          </Box>
        </Box>
      </Box> */}
      {/* {alertMessage && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {alertMessage}
        </Alert>
      )} */}
      <Box display="flex" justifyContent="center" pt={2}>
        <Button
          disabled={posting}
          endIcon={posting ? <CircularProgress size={20} /> : null}
          variant="contained"
          style={{
            padding: "10px 50px",
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
      {/* <form onSubmit={handleSubmit}>
        <div className="my-3">
          {alertMessage && <Alert severity="error">{alertMessage}</Alert>}
        </div>
        <div className="flex justify-center items-center">
          <Button
            disabled={posting}
            endIcon={posting ? <CircularProgress size={20} /> : null}
            label="Submit"
            type="submit"
          />
        </div>
      </form> */}
    </Box>
  );
}

type Prop = {
  title: string;
  value: any;
};

const CustomPreviewP = ({ title, value }: Prop) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="60% 40%"
      // justifyContent="space-between"
      // width="90%"
      borderBottom="1px solid rgba(0, 0, 0, 0.1)"
      pt={1.5}
    >
      <Typography fontSize="18px" fontFamily=" poppins_regular">
        {title}
      </Typography>
      <Typography fontSize="18px" fontFamily=" poppins_regular">
        {value}
      </Typography>
    </Box>
  );
};

function Title({ value }: any) {
  return (
    <Box display="flex" alignItems="center" pt={2} pb={2}>
      <Box height="20px" width="5px" bgcolor="#002060" borderRadius="5px"></Box>
      <Typography ml="10px" fontSize="20px" fontWeight="600" color="#002060">
        {value}
      </Typography>
    </Box>
  );
}

type Props = {
  title: string;
  mt?: number;
};

export const LabelCard = ({ title }: Props) => {
  return (
    <Typography color="#969192" fontSize="16px">
      {title}
    </Typography>
  );
};
