import React, { useEffect, useRef } from "react";
import { useDataContext } from "../context/DataContext";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { tick } from "../assets";
import CopyToClipboard from "../views/CopyToClipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import { KeyboardBackspace } from "@mui/icons-material";
import { enclosures, formState } from "./form";

function Success() {
  const navigate = useNavigate();
  const { setBocwMember } = useDataContext();
  const formFieldData = formState.useState((s) => s);
  const stateFile = enclosures.useState((s) => s);
  const id = useParams();
  const ref: any = useRef();

  useEffect(() => {
    setBocwMember(null);
  }, []);

  const handleCopy = () => {
    let val = id?.id || "";
    navigator.clipboard.writeText(val);
    toast.success("Copied to Clipboard!");
  };

  const handleLogin = () => {

    enclosures.update((s) => {
      s["sign"] = null;
      s["applicantPhoto"] = null;
      s["aadharFile"] = null;

    });

    formState.update((s) => {
      s["aadharFile"] = "";
      s["aadharNumber"] = "";
      s["applicantPhoto"] = "";
      s["batchNumber"] = "";
      s["bocRegNo"] = "";
      s["caste"] = "";
  
      s["course"] = {
        name: '',
        id: '',
        courseId: '',
      };
      s["dateOfBirth"] = '';
      s["district"] = {
        id:"",
        name:"",
        code:""
      };
      s["education"] = {
        name:"",
        id:"",
        courses: []
      };
      s["fatherName"] ="";
      s["gender"] = "";
      s["houseNumber"] = "";
      s["mobile"] = "";
      s["motherName"] = "";
      
  
  s["name"] = "";
  s["pincode"] = "";
  s["renewedUpto"] = "";
  s["reqcode"] = "";
  s["sign"] = "";
  s["state"] = "";
  s["village"] = "";
  s["workerAadharNumber"] = "";
  s["workerMobile"] = "";
  s["workerName"] = "";
  s["workerRationCardNumber"] = "";
  s["year"]= "";
  
  
  
    });

    navigate("/login");
  }

  
    
  

  return (
    <Box
      height="80vh"
      display="flex"
      alignItems="center"
      // justifyContent="center"
      flexDirection="column"
    >
        <Box sx={{ width:"100%",pl:2,mt:14 }}>
          <Box display="flex" alignItems="center" gap={1} color="#2d5696" onClick={handleLogin}>
            <KeyboardBackspace />
            <p>Go to Login page</p>
          </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        pt={10}
        gap={2}
      >
        <img src={tick} style={{ width: "40px" }} />
        <Typography variant="h5" textAlign="center">
          Registration Application Submitted
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mt={4}>
        <Box
          onClick={handleCopy}
          bgcolor="#fef5f5"
          width="400px"
          mt={2}
          borderRadius="10px"
          display="flex"
          justifyContent="space-around"
          p={2}
        >
          <Typography>Batch number :</Typography>
          <Box display="flex" gap={2}>
            <Typography color="green">{id?.id}</Typography>
            <ContentCopyIcon />
          </Box>

          {/* <CopyToClipboard id={id?.id} /> */}
        </Box>
      </Box>
      <Typography pt={20}>
        Thank you for applying, Our team will provide you with the credentials
        to login to view the online courses.
      </Typography>
    </Box>
  );
}

export default Success;
