import { Box, Typography } from "@mui/material";

function Title({ value, mt }: any) {
  return (
    <Box display="flex" alignItems="center" mt={`${mt}px`}>
      <Box height="20px" width="5px" bgcolor="#002060" borderRadius="5px"></Box>
      <Typography ml="10px" variant="h5" color="#002060">
        {value}
      </Typography>
    </Box>
  );
}

export default Title;
